<script lang="ts" setup>
   const props = defineProps<{
      title?: string;
      isStart?: boolean;
      absolute?: boolean;
      width?: string;
      isOpen?: boolean;
   }>();

   const emit = defineEmits<{
      (event: 'close'): void;
   }>();
</script>
<template>
   <div
      class="fixed inset-0"
      :class="isOpen ? 'z-40' : 'z-0'"
      @click.prevent="emit('close')"
   />
   <div
      v-show="isOpen"
      :class="[
         !props.absolute ? 'fixed' : 'fixed sm:absolute',
         props.isStart ? 'start-0' : 'end-0',
         props.width ? props.width : 'sm:w-2/3 md:w-1/2 xl:w-2/5 ',
      ]"
      class="shadow-2xl bg-white top-0 bottom-0 z-40 overflow-auto scrollbar-hidden"
   >
      <slot></slot>
   </div>
</template>
